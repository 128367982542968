import { NgRedux } from '@angular-redux/store';
import { AnyAction } from 'redux';
import { Observable } from 'rxjs';

import { IRootState } from 'core/state/root-state.model';
import { createSelector } from 'reselect';

export abstract class StateBaseService<State, Action extends AnyAction> {
	constructor(private _state: NgRedux<IRootState>) { }

	protected abstract getSubstate: (state: IRootState) => State;

	protected dispatch(action: Action): void {
		this._state.dispatch(action);
	}

	protected select<T>(subselector: (substate: State) => T): Observable<T> {
		return this._state.select(
			createSelector(this.getSubstate, subselector)
		);
	}
}
