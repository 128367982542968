import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';

import { AppComponent } from 'app.component';
import { APP_ROUTES } from 'app.routes';

import { CoreModule } from 'core/core.module';

import { SharedModule } from 'shared/shared.module';

import { CurrentModule } from 'current/current.module';
import { DashboardModule } from 'dashboard/dashboard.module';

@NgModule({
	declarations: [
		AppComponent
	],
	imports: [
		BrowserModule,
		RouterModule,
		RouterModule.forRoot(APP_ROUTES),

		CoreModule,
		SharedModule,

		CurrentModule,
		DashboardModule
	],
	providers: [],
	bootstrap: [AppComponent]
})
export class AppModule { }
