import { Routes } from '@angular/router';

import { LoginComponent } from 'core/components/login/login.component';
import { MainComponent } from 'core/components/main/main.component';
import { SidebarComponent } from 'core/components/sidebar/sidebar.component';

import { AuthGuardService } from 'core/services/auth/auth-guard.service';

import { CURRENT_ROUTES } from 'current/current.routes';
import { DASHBOARD_ROUTES } from 'dashboard/dashboard.routes';

export const APP_ROUTES: Routes = [{
	path: 'login', component: LoginComponent
}, {
	path: '', component: MainComponent, canActivate: [AuthGuardService], children: [{
		path: '', component: SidebarComponent, outlet: 'sidebar', pathMatch: 'prefix'
	}, {
		path: '', children: [
			...DASHBOARD_ROUTES,
			...CURRENT_ROUTES,
			{
				path: '**', redirectTo: '/login', pathMatch: 'full'
			}
		]
	}]
}];
