import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { SharedModule } from 'shared/shared.module';

import { CurrentService } from './services/current.service';

import { CurrentGameEditComponent } from './components/current-game-edit/current-game-edit.component';

@NgModule({
	declarations: [
		CurrentGameEditComponent
	],
	imports: [
		CommonModule,
		FormsModule,
		RouterModule,

		SharedModule
	],
	providers: [
		CurrentService
	],
	exports: []
})
export class CurrentModule { }
