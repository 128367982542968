import { ControlValueAccessor } from '@angular/forms';

export abstract class CustomControlBase<T> implements ControlValueAccessor {
	public isDisabled: boolean = false;
	public model: T;

	private _propagateChange: (_: any) => {};

	public registerOnChange(fn: any): void {
		this._propagateChange = fn;
	}

	// tslint:disable-next-line:no-empty
	public registerOnTouched(): void { }	// required to satisfy ControlValueAccessor

	public setDisabledState(isDisabled: boolean): void {
		this.isDisabled = isDisabled;
	}

	public writeValue(obj: T): void {
		if (obj !== undefined) {
			this.model = obj;
		}
	}
}
