import { Component } from '@angular/core';

@Component({
	selector: 'q4sg-main',
	templateUrl: './main.component.html',
	styleUrls: ['./main.component.scss']
})
export class MainComponent {

}
