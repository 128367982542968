import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiBaseService } from 'core/services/api/api-base.service';

import { ICurrentGameModel } from 'current/models/current.models';

@Injectable()
export class CurrentService extends ApiBaseService {
	constructor(http: HttpClient) {
		super(http);
		this.baseUrl = 'current';
	}

	public getCurrentGame(): Observable<ICurrentGameModel> {
		return this.get('');
	}

	public updateCurrentGame(model: ICurrentGameModel): Observable<void> {
		return this.put('', model);
	}
}
