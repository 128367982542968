import { NgRedux } from '@angular-redux/store';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { APP_STATE_ACTIONS } from 'core/state/app-state.actions';
import { IAppState } from 'core/state/app-state.model';
import { getAppState, IRootState } from 'core/state/root-state.model';
import { ISubstateAction } from 'core/state/state-actions';
import { StateBaseService } from './state-base.service';

@Injectable()
export class AppStateService extends StateBaseService<IAppState, ISubstateAction<APP_STATE_ACTIONS>> {
	protected getSubstate = getAppState;

	constructor(state: NgRedux<IRootState>) {
		super(state);
	}

	public watchAppState = (): Observable<IAppState> => this.select((x) => ({ ...x }));
}
