import { DEFAULT_APP_STATE, IAppState } from './app-state.model';
import { DEFAULT_UI_STATE, IUIState } from './ui-state.models';

export interface IRootState {
	app: IAppState;
	ui: IUIState;
}

export const DEFAULT_ROOT_STATE = {
	app: DEFAULT_APP_STATE,
	ui: DEFAULT_UI_STATE
};

export const getRootState = (state: IRootState) => state;

// substate trees
export const getAppState = (state: IRootState) => state.app;
export const getUIState = (state: IRootState) => state.ui;
