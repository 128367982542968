import { NgRedux } from '@angular-redux/store';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { getUIState, IRootState } from 'core/state/root-state.model';
import { ISubstateAction } from 'core/state/state-actions';
import { UI_STATE_ACTIONS } from 'core/state/ui-state.actions';
import { IUIState } from 'core/state/ui-state.models';
import { StateBaseService } from './state-base.service';

@Injectable()
export class UIStateService extends StateBaseService<IUIState, ISubstateAction<UI_STATE_ACTIONS>> {
	protected getSubstate = getUIState;

	constructor(state: NgRedux<IRootState>) {
		super(state);
	}

	public watchUIState = (): Observable<IUIState> => this.select((x) => ({ ...x }));
}
