import { NgModule } from '@angular/core';

import { DashboardComponent } from './components/dashboard/dashboard.component';

@NgModule({
	declarations: [
		DashboardComponent
	],
	imports: [],
	providers: [],
	exports: []
})
export class DashboardModule { }
