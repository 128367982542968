import { Component } from '@angular/core';
import { of, Subscription } from 'rxjs';
import { catchError, filter, take } from 'rxjs/operators';

import { ObservableComponentBase } from 'core/components/base/observable-component.base';

import { ICurrentGameModel } from 'current/models/current.models';
import { CurrentService } from 'current/services/current.service';

@Component({
	selector: 'q4sg-current-game-edit',
	templateUrl: './current-game-edit.component.html',
	styleUrls: ['./current-game-edit.component.scss']
})
export class CurrentGameEditComponent extends ObservableComponentBase {
	public model: ICurrentGameModel = {} as ICurrentGameModel;

	constructor(private _currentService: CurrentService) {
		super();
	}

	protected initSubscriptions(): Subscription[] {
		return [
			this._currentService.getCurrentGame()
				.pipe(
					take(1)
				)
				.subscribe((result) => {
					this.model = result;
				})
		];
	}

	public addDeveloper(): void {
		this.model.developers.push({
			name: '',
			url: ''
		});
	}

	public removeDeveloper(index: number): void {
		this.model.developers.splice(index, 1);
	}

	public addQuest(): void {
		this.model.quests.push({ description: '' });
	}

	public removeQuest(index: number): void {
		this.model.quests.splice(index, 1);
	}

	public addProgress(): void {
		this.model.progress.push({ description: '' });
	}

	public removeProgress(index: number): void {
		this.model.progress.splice(index, 1);
	}

	public save(): void {
		this._currentService.updateCurrentGame(this.model)
			.pipe(
				take(1),
				catchError((err, _) => {
					// TODO: log / show error
					return of(undefined);
				}),
				filter((result) => {
					return result !== undefined;
				})
			)
			.subscribe();	// TODO: show success msg
	}
}
