import { combineReducers } from 'redux';

import { appReducer } from './app-state.reducer';
import { uiReducer } from './ui-state.reducer';

import { ROOT_STATE_ACTIONS } from './root-state.actions';
import { DEFAULT_ROOT_STATE, IRootState } from './root-state.model';
import { IStateAction } from './state-actions';

const combinedSubreducers = combineReducers<IRootState, IStateAction>({
	app: appReducer,
	ui: uiReducer
});

// attach and process root actions first, then cascade to subreducers
export const rootReducer = (state: IRootState = DEFAULT_ROOT_STATE, action: IStateAction): IRootState => {
	switch (action.type) { }

	return combinedSubreducers(state, action);
};
