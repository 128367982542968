import { Component, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { CustomControlBase } from '../custom-control-base/custom-control-base.component';

import { IHyperlinkModel } from 'shared/models/utility.models';

@Component({
	selector: 'q4sg-hyperlink',
	templateUrl: './hyperlink.component.html',
	styleUrls: ['./hyperlink.component.scss'],
	providers: [{
		provide: NG_VALUE_ACCESSOR,
		useExisting: forwardRef(() => HyperlinkComponent),
		multi: true
	}]
})
export class HyperlinkComponent extends CustomControlBase<IHyperlinkModel> {
	@Input()
	public label: string = 'Label';
}
