import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';

import { IAuthRequestModel } from '../../models/auth.models';
import { AuthManagerService } from '../../services/auth/auth-manager.service';
import { AuthService } from '../../services/auth/auth.service';
import { IStorageService, StorageServiceToken } from '../../services/storage/storage.service';

@Component({
	selector: 'q4sg-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
	public model: IAuthRequestModel = {} as IAuthRequestModel;

	private _redirectUrl = '/Dashboard';

	constructor(
		private _authService: AuthService,
		private _authManager: AuthManagerService,
		private _router: Router,
		@Inject(StorageServiceToken) private _storage: IStorageService
	) { }

	public ngOnInit(): void {
		if (this._authManager.getToken()) {
			this._router.navigate([this._redirectUrl]);
			return;
		}
	}

	public login(): void {
		this._authService.login(this.model)
			.pipe(
				take(1)
			)
			.subscribe((s) => {
				if (s && s.token) {
					this._authManager.setAuthentication(s);

					this._router.navigate(['/Dashboard']);
				}
			});
	}
}
