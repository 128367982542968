import { NgRedux } from '@angular-redux/store';
import { Injectable } from '@angular/core';

import { StateBaseService } from './state-base.service';

import { IRootStateAction, ROOT_STATE_ACTIONS } from 'core/state/root-state.actions';
import { getRootState, IRootState } from 'core/state/root-state.model';
import { Observable } from 'rxjs';

@Injectable()
export class RootStateService extends StateBaseService<IRootState, IRootStateAction> {
	protected getSubstate = getRootState;

	constructor(state: NgRedux<IRootState>) {
		super(state);
	}
}
