import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { HyperlinkComponent } from './components/hyperlink/hyperlink.component';

@NgModule({
	declarations: [
		HyperlinkComponent
	],
	imports: [
		FormsModule
	],
	exports: [
		HyperlinkComponent
	]
})
export class SharedModule { }
